<template>
  <div class="tech-support">
    <h1 class="tech-support__title">{{ $t('technical-support-1') }}</h1>
    <div class="tech-support__form">
      <ui-input
        label="E-mail"
        v-model="form.email"
        required-field
        :error="$v.form.email.$anyError"
        :error-text="getEmailErrorText"
      />
      <ui-input
          :label="$t('subject')"
          required-field
          v-model="form.subject"
          :error="$v.form.subject.$anyError"
      />
      <ui-textarea
        :label="$t('problem-description')"
        required-field
        v-model="form.textarea"
        :error="$v.form.textarea.$anyError"
        height="80"
        radius="10"
      />
      <div class="tech-support__form-upload-file">
        <h2>{{ $t('attach-files') }}</h2>
        <div class="upload-wrapper">
          <label>
            <input type="file" ref="file" @input="uploadDocument" accept="image/*" >
            <span class="upload-wrapper__btn">
              <img src="@/assets/svg/icon-plus-white.svg" alt="" />
            </span>
          </label>
          <a
              v-for="(item, key) in form.documentSrc"
              :href="item.url"
              target="_blank"
              style="display: flex"
              :key="key"
              class="upload-wrapper-file"
          >
            <img
                src="@/assets/svg/icon-docs-grey.svg"
                alt=""
            />
            <div class="upload-wrapper-file-close" @click="showDeleteAlert($event, key)">
              <img
                  src="@/assets/svg/close/white.svg"
                  alt=""
              />
            </div>
            <div class="upload-wrapper-file-info">
              <p>{{ item.originalname }}</p>
              <span>{{ getFullDate(new Date())}}</span>
            </div>
          </a>
        </div>
      </div>
      <ui-button :disabled="$v.form.$anyError || shouldDisabled" @click="submit">{{ $t('send') }}</ui-button>
    </div>
    <ui-alert v-model="showAlert">
      <h2 class="alert-title">{{ $t('your-request-has-been-sent') }}</h2>
      <ui-button @click="$router.push('/')">{{ $t('back-to-home-screen') }}</ui-button>
    </ui-alert>
    <UiAlert v-model="visibleDeleteAlert">
      <div class="ui-alert__title">{{ $t('do-you-really-want-to-delete') }}</div>
      <div class="ui-alert__action">
        <ui-button @click="deleteFile" color="error">{{ $t('delete') }}</ui-button>
        <ui-button @click="visibleDeleteAlert = false" color="white">{{ $t('cancel-2') }}</ui-button>
      </div>
    </UiAlert>
  </div>
</template>

<script>
import {required, email, minLength} from 'vuelidate/lib/validators'
import UiInput from "@/components/ui/UiInput";
import UiTextarea from "@/components/ui/UiTextarea";
import UiButton from "@/components/ui/UiButton";
import UiAlert from "@/components/ui/UiAlert";
import {mapActions, mapGetters} from "vuex";
import {getFullDate} from "@/utils/general";
export default {
  name: "TechSupport",
  components: {UiAlert, UiButton, UiTextarea, UiInput},
  data() {
    return {
      form: {
        email: '',
        subject: '',
        textarea: '',
        documentFile: [],
        documentSrc: [],
      },
      showAlert: false,
      emailError: false,
      getFullDate,
      documentId: '',
      visibleDeleteAlert: false
    }
  },

  methods: {
    ...mapActions(['request', 'reqUpload']),
    uploadDocument({ target }) {
      this.reqUpload({
        file: target.files[0],
        type: "document",
      }).then((res) => {
        this.form.documentFile.push(res.data.filename)
        this.form.documentSrc.push(res.data)
        this.$refs.file.value = null
      })
    },
    submit() {
      this.$v.form.$touch()
      if(!this.$v.form.$anyError) {
        this.request({
          "email": this.form.email,
          "description": this.form.textarea,
          "subject": this.form.subject,
          "files": this.form.documentFile
        }).then(() => {
          this.showAlert = true
        }).catch(() => {
          this.emailError = true
        })
      }
    },
    deleteFile() {
      this.form.documentFile = this.form.documentFile.filter((elem, idx) => idx !== this.documentId);
      this.form.documentSrc = this.form.documentSrc.filter((elem, idx) => idx !== this.documentId);
      this.visibleDeleteAlert = !this.visibleDeleteAlert
    },

    showDeleteAlert (e, key) {
      e.preventDefault();
      this.visibleDeleteAlert = !this.visibleDeleteAlert,
      this.documentId = key
    }
  },
  computed: {
    ...mapGetters(['getRequests', 'getUser']),
    getEmailErrorText() {
      return !this.$v.form.email.email ? this.$t('error-email') : '' ||
      this.emailError ? this.$t('email-already-exists') : ''
    },
    shouldDisabled() {
      let empty = ['email', 'subject', 'textarea'].find(prop => !this.form[prop])
      return empty !== undefined
    },
  },

  validations() {
    return {
        form: {
          email: {
            required,
            email
          },
          subject: {
            required,
          },
          textarea: {
            required,
            minLength: minLength(8)
          },
        }
    }
  },
  mounted() {
    this.form.email = this.getUser?.email
  }
}
</script>

<style lang="scss" scoped>
.tech-support {
  display: flex;
  flex-direction: column;
  gap: 40px;
  background: #F5F5F5;
  min-height: 100vh;
  padding: 150px 50px 50px 50px;

  @media (max-width: 768px) {
    gap: 25px;
    padding: 109px 20px 112px 20px;
  }

  &__title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #343432;
  }

  &__form {
    align-self: center;
    display: flex;
    flex-direction: column;
    max-width: 434px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    padding: 32px 34px 33px 32px;
    gap: 20px;

    @media (max-width: 768px) {
      padding: 30px 12px;
    }

    &-upload-file {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h2 {
        margin-left: 30px;
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #343432;

        &.error {
          color: #E21F1F;
        }
      }

      .upload-wrapper {
        display: flex;
        flex-wrap: nowrap;
        gap: 10px;
        overflow-x: scroll;
        right: 1rem;
        width: 100%;
        height: 100%;
        padding: 10px 0;

        &::-webkit-scrollbar {
          width: 20px;
          height: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9A9A9A;
          border-radius: 10px;
        }

        input {
          display: none;
        }

        .upload-wrapper__btn {
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #E21F1F;
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
        }

        &-file {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          max-width: 116px;
          min-width: 114px;
          gap: 10px;

          img {
            width: 60px;
            height: 60px;
          }

          &-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;

            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              text-align: center;
              word-break: break-all;
              color: #1B1A1F;
            }

            span {
              font-weight: 400;
              font-size: 10px;
              line-height: 112.7%;
              color: #BFBDBD;
              width: 58px;
              text-align: center;
            }
          }

          &-close {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 24px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #E21F1F;

            img {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
    }

    button {
      margin-top: 20px;
    }
  }

  .alert-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #343432;
    max-width: 237px;
  }
}
</style>
